import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import StyledPagePlantesEtSols from './StyledPagePlantesEtSols';

export const PagePlantesEtSols = () => {
    const { pagePlantesEtSols } = useStaticQuery(
        graphql`
            query {
                pagePlantesEtSols:datoCmsPagePlantesEtSol {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    banniereNode {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPagePlantesEtSols>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage} / <small>Plantes et Sols</small></h3>
                </div>
                <div className='container-img-txt'>
                    <img id='logo' alt='logo' src={logoVitaFons} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pagePlantesEtSols.encart1Node.childMarkdownRemark.html}} />
                </div>
                <div className='little-separator' />
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pagePlantesEtSols.encart2Node.childMarkdownRemark.html}} />
            </div>
            <div className='separator' dangerouslySetInnerHTML={{ __html: pagePlantesEtSols.banniereNode.childMarkdownRemark.html}} />
            <div className='container-page'>
                <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pagePlantesEtSols.encart3Node.childMarkdownRemark.html}} />
                <small id='sign-copy'>© Vita Fons II Limited, 1993, 1998, 2018</small>
            </div>
        </StyledPagePlantesEtSols>
    )
}
